<script>
export default {
  props: {
    tipoPagamento: { type: Object },
  },
  data() {
    return {
        
    }
  },
}
</script>

<template>
<div class="tipoPagamento">
  <p>Nome: {{ tipoPagamento.tiponome }}</p>
  <p>Adquirente: <b-badge data-name="adquirente" class="field-adquirente" v-bind:variant="(tipoPagamento.adquerente) ? 'success' : 'danger'">{{ (tipoPagamento.adquerente) ? 'Sim' : 'Não' }}</b-badge></p>
  <p>Touch: <b-badge data-name="touch" class="field-touch" v-bind:variant="(tipoPagamento.touch) ? 'success' : 'danger'">{{ (tipoPagamento.touch) ? 'Sim' : 'Não' }}</b-badge></p>
  <p>Código DF-e: {{ tipoPagamento.tpag }}</p>
  <p>Tecla Atalho: <strong>{{ tipoPagamento.teclaatalho }}</strong></p>
  <p>Criado: <b-badge data-name="created_at" class="field-created_at" variant="secondary">{{ tipoPagamento.created_at }}</b-badge></p>
  <p>Última alteração: <b-badge data-name="updated_at" class="field-updated_at" variant="secondary">{{ tipoPagamento.updated_at }}</b-badge></p>
</div>
</template>