<script>
import {listTPag} from "@/components/defaults/tpag";
import {required} from "vuelidate/lib/validators";

export default {
  props: { empresa: { type: Object, required: true }},
  data() {
    return {
      titleBody: 'Incluir Forma de Pagamento',
      tipoPagamento: {
          tiponome: null,
          adquerente: false,
          touch: false,
          tpag: null,
          teclaatalho: '',
          empresa_id: null,
      },
      listTPagamento: [],
      submitted: false,
    }
  },
  validations() {
    return {
      tipoPagamento: {
        tiponome: { required },
        tpag: { required },
      }
    }
  },
  mounted() {
    this.$emit('newTitle', this.titleBody);
    this.listTPagamento = listTPag;
    this.tipoPagamento.empresa_id = this.empresa.id;
  },
  methods: {
    makeToast(variant = null, message = 'Error generic') {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: 'b-toaster-bottom-right',
        variant: variant,
        solid: true,
        appendToast: true
      });
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.tipoPagamento.$error) {
        this.makeToast('danger', 'Erro de validação!');
      } else {
        this.setPost();
      }
    },
    setPost() {
      this.$emit('doPost', this.tipoPagamento);
    }
  },
}
</script>

<template>
<div class="card">
  <div class="card-body">
    <form id="form-tipo-pagamento" role="form" class="form-horizontal" @submit.prevent="formSubmit" ref="form">
      <b-card no-body class="mb-1">
        <b-card-body>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-3">
              <div class="form-group">
                <label for="tipo_pagamento_id" class="col-form-label">Código</label>
                <div class="col">
                  <input disabled v-model="tipoPagamento.id" class="form-control text-right" type="text" placeholder="Gerado pelo sistema" id="tipo_pagamento_id">
                </div>
              </div>
            </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-8">
              <div class="form-group">
                <label for="tipo_pagamento_tiponome" class="col-form-label">Nome da Forma de Pagamento</label>
                  <div class="col">
                    <input v-model="tipoPagamento.tiponome" class="form-control" type="text"
                     placeholder="Dinheiro, Cartão, Vale..."
                     id="tipo_pagamento_tiponome"
                     :class="{ 'is-invalid': submitted && $v.tipoPagamento.tiponome.$error }"
                    >
                    <div v-if="submitted && $v.tipoPagamento.tiponome.$error" class="invalid-feedback">
                      <span v-if="!$v.tipoPagamento.tiponome.required"> {{ $t('mesages.validation.required') }}</span>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-4">
              <div class="form-group">
                <div class="col">
                    <b-form-checkbox
                     id="tipo-pagamento-touch"
                     v-model="tipoPagamento.touch"
                     name="touch"
                     value="1"
                     unchecked-value="0"
                    >
                      Mostrar no Touch
                    </b-form-checkbox>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-4">
              <div class="form-group">
                <div class="col">
                  <b-form-checkbox
                   id="tipo-pagamento-adquerente"
                   v-model="tipoPagamento.adquerente"
                   name="adquerente"
                   value="true"
                   unchecked-value="false"
                  >
                      Com Adquirente
                  </b-form-checkbox>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-2">
              <div class="form-group">
                <div class="col">
                  <label>Tecla Atalho</label>
                  <b-form-input
                   id="tipo-pagamento-teclaatalho"
                   v-model="tipoPagamento.teclaatalho"
                   name="teclaatalho"
                  >
                  </b-form-input>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12">
              <b-form-group label="Formas de Pagamento DF-e" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                 id="tipo-pagamento-tpag"
                 v-model="tipoPagamento.tpag"
                 :options="listTPagamento"
                 :aria-describedby="ariaDescribedby"
                 name="tpag"
                >
                <b-form-invalid-feedback
                 v-if="submitted && $v.tipoPagamento.tpag.$error"
                 :state="($v.tipoPagamento.tpag.required)
                ">
                  {{ $t('mesages.validation.selectone') }}
                </b-form-invalid-feedback>
                </b-form-radio-group>
              </b-form-group>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </form>
  </div>
  <div class="card-body">
    <div class="col-md-12 mt-10">
      <button class="btn btn-light"
       @click="formSubmit">Gravar</button>
    </div>
  </div>
</div>
</template>

<style>
.custom-control.custom-control-inline.custom-radio {
    margin-top: 12px;
    min-width: 300px;
}
</style>