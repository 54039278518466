<script>
import appConfig from "@/app.config";
import { authEasyMethods, notificationMethods } from '@/state/helpers';
import { http } from '../../../../helpers/easyindustriaapi/config';
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import List from "./list";
import Insert from "./insert";
import Edit from "./edit";

export default {
  page: {
      title: 'Formas de Pagamento',
      meta: [{ name: 'description', content: appConfig.description }]
  },
  components: { Layout, PageHeader, List, Insert, Edit },
  data() {
    return {
      currentTipoPagamento: {},
      tiposPagamento: [],
      inserindo: false,
      editando: false,
      titleBody: 'Formas de Pagamento',
      items: [
        {
        text: "Dashboard",
        href: "/",
        },
        {
        text: "Parâmetros",
        href: "/",
        active: true,
        },
        {
        text: "Formas de Pagamento",
        href: "/parametros/tipospagamento",
        active: true,
        }
      ],
    }
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    isHide() {
      return (!this.inserindo && !this.editando);
    },
  },
  created() {
    this.currentUser    = JSON.parse(localStorage.getItem('user'));
    this.currentEmpresa = JSON.parse(localStorage.getItem('currentEmpresa'));
  },
  mounted() {
      this.getData();
  },
  methods: {
    ...authEasyMethods,
    ...notificationMethods,
    makeToast(variant = null, message = 'Error generic') {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: 'b-toaster-bottom-right',
        variant: variant,
        solid: true,
        appendToast: true
      });
    },
    changeHomeTitle(newTitle) {
      this.titleBody = newTitle;
    },
    onLoader() {
      if (document.getElementById("preloader").style.display === "none")
      {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";
      }
    },
    offLoader() {
      if (document.getElementById("preloader").style.display === "block")
      {
        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      }
    },
    async getData() {
      this.onLoader();

      try {
        let response = await http.get('/tipopagamento?empresa_id=' + this.currentEmpresa.id);

        if (response.status === 200) {
          this.tiposPagamento = response.data;
          this.offLoader();
        }
      } catch (error) {
        this.makeToast('danger', error.response);
        this.offLoader();
      }
    },
    setTipoPagamento(tipoPagamento) {
      this.currentTipoPagamento = tipoPagamento;
    },
    insert() {
      this.inserindo = !this.inserindo;
    },
    edit(oldTipoPagamento) {
      this.setTipoPagamento(oldTipoPagamento);
      this.editando = !this.editando;
    },
    async doPut(oldTipoPagamento) {
      this.onLoader();
      oldTipoPagamento.empresa_id = this.currentEmpresa.id;
      let response = await http.put('/tipopagamento/' + oldTipoPagamento.id + '?empresa_id='+this.currentEmpresa.id, oldTipoPagamento)
        .catch((error) => {
          let messageErro = error.response.data;

          switch (error.response.status) {
            case 406:
              this.makeToast('danger', 'Erro 406: ' + (messageErro.tipo) ? messageErro.tiponome : messageErro.tpag);
              this.offLoader();
              break;
          case 404:
            this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
            this.offLoader();
            break;
          
            default:
              this.makeToast('danger', error.message);
              this.offLoader();
              break;
          }
        });
      
      if (response && response.status === 200) {
        this.getData();
        this.makeToast('success', 'Registro alterado');
        this.back();
      }
    },
    async doPost(newTipoPagamento) {
      this.onLoader();
      newTipoPagamento.empresa_id = this.currentEmpresa.id;
      let response = await http.post('/tipopagamento?empresa_id='+this.currentEmpresa.id, newTipoPagamento)
        .then((res) => {
          console.log({'then': res});
        })
        .finally((res) => {
          console.log({'finally': res});
        })
        .catch((error) => {
          console.log({'catch': response});
          console.error
          this.makeToast('danger', error.response);
          this.offLoader();
        });

      if (response && response.status && response.status === 200) {
        this.getData();
        this.makeToast('success', 'Registro incluído');
        this.back();
      }
    },
    async doDelete(xTipoMovimento) {
      this.onLoader();
      xTipoMovimento.empresa_id = this.currentEmpresa.id;
      let response = await http.delete('/tipopagamento/' + xTipoMovimento.id + '?empresa_id='+this.currentEmpresa.id, xTipoMovimento)
        .catch((error) => {
          let messageErro = error.response.data;

          switch (error.response.status) {
            case 406:
              this.offLoader();
              this.makeToast('danger', 'Erro 406: ' + messageErro);
              break;
          
            default:
              this.offLoader();
              this.makeToast('danger', error.message);
              break;
          }
        });
      
      if (response && response.status === 200) {
        this.getData();
        this.back();
        this.makeToast('success', 'Registro excluído');
      }
    },
    back() {
      this.inserindo = false;
      this.editando  = false;
    },
  },
}
</script>

<template>
<Layout>
  <PageHeader :title="titleBody" :items="items" />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
              <div class="col-sm-12 col-md-6"></div>
              <div class="col-sm-12 col-md-6 text-md-right">
                <button @click="insert()" v-if="isHide" type="button" class="btn btn-success">+ Incluir Pagamento</button>
                <button @click="back()" v-if="!isHide" class="btn btn-secondary">Voltar</button>
              </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div v-if="!isHide" class="card-body">
          <Insert v-if="inserindo"
           :empresa="currentEmpresa"
           @newTitle="changeHomeTitle"
           @doPost="doPost"
          />
          <Edit v-if="editando"
           :empresa="currentEmpresa"
           :oldTipoPagamento="currentTipoPagamento"
           @newTitle="changeHomeTitle"
           @doPut="doPut"
          />
        </div>
        <List
         v-if="isHide"
         :currentEmpresa="currentEmpresa"
         :listTiposPagamento="tiposPagamento"
         :hide="isHide"
         @edit="edit"
         @newTitle="changeHomeTitle"
         @setTipoPagamento="setTipoPagamento"
         @doDelete="doDelete"
        />
      </div>
    </div>
  </div>
</Layout>
</template>