export const listTPag = [
  { value: '01', text: 'Dinheiro' },
  { value: '02', text: 'Cheque' },
  { value: '03', text: 'Cartão Crédito' },
  { value: '04', text: 'Cartão Débito' },
  { value: '05', text: 'Cédito Loja' },
  { value: '10', text: 'Vale Alimentação' },
  { value: '11', text: 'Vale Refeição' },
  { value: '12', text: 'Vale Presente' },
  { value: '13', text: 'Vale Combustível' },
  { value: '15', text: 'Boleto Bancário' },
  { value: '16', text: 'Depósito Bancário' },
  { value: '17', text: 'Pagamento Instantâneo (PIX)' },
  { value: '18', text: 'Transferência Bancária, Carteira Digital' },
  { value: '19', text: 'Programa de fidelidade, Cashback, Crédito Virtual' },
  { value: '90', text: 'Sem Pagamento' },
  { value: '99', text: 'Outros' },
];